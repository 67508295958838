import {useEffect, useState} from 'react'
import logo from './logo.svg';
import './App.css';
import lottie from "lottie-web";
import abstractPopBckg from './abstractPopBckg.jpg';
import ImageGallery from './ImageGallery';
// import reactLogo from "../static/react-logo.json";

function App() {
    for(let x = 1; x < 53; x++) {
        console.log(`import from './${x}.jpg'`)
    }
  useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#react-logo"),
    });
  }, []);

  return (
    <div className="App">
      <img className='backgroundImage' src={abstractPopBckg}/>
      <div className="contentContainer">
        <div className='header'>
          <h2 className='gradientText'>Abstract Pop</h2>
          <div className='menuContainer'>
            <div className='menuButtonContainer'>
              <div className='buttonUIs first'></div>
              <div className='buttonUIs'></div>
              <div className='buttonUIs'></div>
            </div>
          </div>
        </div>

        <div className='content'>
          {/*  */}

          <div className='titleComponent'>
            <div className='primaryTitleContainer'>
              <h4 className='primaryTitle'>AbStRacT PoP</h4>
            </div>
            <h4 className='hostTitle'>Hosted By April Omari</h4>
            <div className='secondaryTitleContainer'>
              <h4 className='secondaryTitle'>Art, Humor, Joy On The Go</h4>
            </div>
          </div>
          
          {/*  */}
          <div className='linksInMyBioContainer'>

            <div className='linksInMyBioTitle'>
              <h4 className='secondaryTitle'>Link In The Bio : </h4>
            </div>
            <div className='linksInMyBioImagesContainer'>
              <div className='linkItems first'></div>
              <div className='linkItems'></div>
              <div className='linkItems'></div>
              <div className='linkItems'></div>
              <div className='linkItems'></div>
              <div className='linkItems'></div>
            </div>
          </div>
          {/*  */}
          <ImageGallery/>
        </div>
      </div>
    </div>
  );
}

export default App;

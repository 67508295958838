




import one from './Art/1.jpeg';
import two from './Art/2.jpeg'
import three from './Art/3.jpeg'
import four from './Art/4.jpeg'
import five from './Art/5.jpeg'
import six from './Art/6.jpeg'
import seven from './Art/7.jpeg'
import eight from './Art/8.jpeg'
import nine from './Art/9.jpeg'
import ten from './Art/10.jpeg'
import eleven from './Art/11.jpeg'
import twelve from './Art/12.jpeg'
import thirteen from './Art/13.jpeg'
import fourteen from './Art/14.jpeg'
import fifteen from './Art/15.jpeg'
import sixteen from './Art/16.jpeg'
import seventeen from './Art/17.jpeg'
import eighteen from './Art/18.jpeg'
import nineteen from './Art/19.jpeg'
import twenty from './Art/20.jpeg'
import twentyOne from './Art/21.jpeg'
import twentytwo from './Art/22.jpeg'
import twentythree from './Art/23.jpeg'
import twentyfour from './Art/24.jpeg'
import twentyfive from './Art/25.jpeg'
import twentysix from './Art/26.jpeg'
import twentyseven from './Art/27.jpeg'
import twentyeight from './Art/28.jpeg'
import twentynine from './Art/29.jpeg'
import thirty from './Art/30.jpeg'
import thirtyone from './Art/31.jpeg'
import thirtytwo from './Art/32.jpeg'
import thirtythree from './Art/33.jpeg'
import thirtyfour from './Art/34.jpeg'
import thirtyfive from './Art/35.jpeg'
import thirtysix from './Art/36.jpeg'
import thirtyseven from './Art/37.jpeg'
import thirtyeight from './Art/38.jpeg'
import thirtynine from './Art/39.jpeg'
import fourty from './Art/40.jpeg'
import fourtyone from './Art/41.jpeg'
import fourtytwo from './Art/42.jpeg'
import fourtythree from './Art/43.jpeg'
import fourtyfour from './Art/44.jpeg'
import fourtyfive from './Art/45.jpeg'
import fourtysix from './Art/46.jpeg'
import fourtyseven from './Art/47.jpeg'
import fourtyeight from './Art/48.jpeg'
import fourtynine from './Art/49.jpeg'
import fifty from './Art/50.jpeg'
import fiftyone from './Art/51.jpeg'


export default function ImageGallery() {
    let arrayOfImages = [
        one, two, three, four, five, six, seven, eight, nine, ten,
        eleven, twelve, thirteen, fourteen, fifteen, sixteen, seventeen,
        eighteen, nineteen, twenty, twentyOne, twentytwo, twentythree, twentyfour, twentyfive,
        twentysix, twentyseven, twentyeight, twentynine, thirty, thirtyone, thirtytwo, thirtythree,
        thirtyfour, thirtyfive, thirtysix, thirtyseven, thirtyeight, thirtynine, fourty, fourtyone,
        fourtytwo, fourtythree, fourtyfour, fourtyfive, fourtysix, fourtyseven, fourtyeight, fourtynine,
        fifty, fiftyone
    ]
    return (
        <div className='imageGalleryGrid'>
            {
                arrayOfImages.map(image => {
                    return (<img className="imageObj" src={image}/>)
                })
            }
        </div>
    )
}